import React, { useContext } from "react"
import styled from "styled-components"
import { Section, Title, Button } from "../utils"
import ShowsImg from "../images/arnospillaert.jpg"
import LanguageContext from "../contexts/LanguageContext"

const Shows = () => {
  const { lang } = useContext(LanguageContext)
  return (
    <Section id="shows">
      <Title
        text={
          lang === "fr" ? "concerts" : lang === "nl" ? "concerten" : "shows"
        }
      />
      <ShowsWrapper>
        <img src={ShowsImg} alt="shows" />
      </ShowsWrapper>
      <a
        href="https://www.bandsintown.com/fr/a/46168-arno"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          text={
            lang === "fr"
              ? "Réserver sa place"
              : lang === "nl"
              ? "koop nu tickets"
              : "get tickets now"
          }
        />
      </a>
    </Section>
  )
}

export default Shows

const ShowsWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  margin-bottom: 3rem;
  & > img {
    width: 100%;
  }
`
