import React, { useState, useContext } from "react"
import styled from "styled-components"
import { Section, Title, Button } from "../utils"
import AlbumImg from "../images/santeboutiquecut.png"
import LanguageContext from "../contexts/LanguageContext"

const Album = () => {
  const [tracks] = useState([
    {
      id: 1,
      name: "THEY ARE COMING",
    },
    {
      id: 2,
      name: "SANTEBOUTIQUE",
    },
    {
      id: 3,
      name: "NATUREL",
    },
    {
      id: 4,
      name: "LES SAUCISSES DE MAURICE",
    },
    {
      id: 5,
      name: "OOSTENDE BONSOIR",
    },
    {
      id: 6,
      name: "CA CHANTE",
    },
    {
      id: 7,
      name: "LADY ALCOHOL",
    },
    {
      id: 8,
      name: "COURT-CIRCUIT DANS MON ESPRIT",
    },
    {
      id: 9,
      name: "TJIP TJIP C'EST FINI",
    },
    {
      id: 10,
      name: "FLASHBACK BLUES",
    },
  ])

  const { lang } = useContext(LanguageContext)

  return (
    <Section id="album">
      <Title text="Album" />
      <AlbumWrapper>
        <ImageWrapper>
          <img src={AlbumImg} alt="santeboutique cover" />
        </ImageWrapper>
        <TrackListWrapper>
          <TrackList>
            {tracks.map(item => {
              return <ListItem key={item.id}>{item.name}</ListItem>
            })}
          </TrackList>
        </TrackListWrapper>
      </AlbumWrapper>
      <a
        href="https://backl.ink/arnosanteboutique"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          text={
            lang === "fr"
              ? "commander maintenant"
              : lang === "nl"
              ? "bestel nu"
              : "order now"
          }
        />
      </a>
    </Section>
  )
}

export default Album

const AlbumWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 3rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  padding: 1rem;
  width: 100%;
  & > img {
    width: 100%;
    @media screen and (max-width: 768px) {
      width: 80%;
      display: block;
      margin: 0 auto;
    }
  }
`

const TrackListWrapper = styled.ol`
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding: 1rem;
  @media screen and (max-width: 350px) {
    padding-left: 5rem;
  }
`

const ListItem = styled.li`
  @media screen and (max-width: 768px) {
    padding: 0.5rem 0;
  }
`

const TrackList = styled.ol`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
