import React from "react"
import styled from "styled-components"
import { FaTwitterSquare, FaFacebookSquare, FaInstagram } from "react-icons/fa"
import { Section, styles } from "../utils"
import Sponsors from "../images/sponsors.jpg"

const SocialMedia = () => {
  return (
    <Section>
      <Separator />
      <IconsWrapper>
        <a href="https://www.facebook.com/arnoofficialpage/">
          <FaFacebookSquare />
        </a>
        <a href="https://twitter.com/arnoofficiel">
          <FaTwitterSquare />
        </a>
        <a href="https://www.instagram.com/arnoofficial/">
          <FaInstagram />
        </a>
      </IconsWrapper>
      <SponsorsWrapper>
        <img src={Sponsors} alt="sponsors" />
      </SponsorsWrapper>
    </Section>
  )
}

export default SocialMedia

const Separator = styled.hr`
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
`

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 3rem 0;
  & > a,
  a:link,
  a:visited {
    font-size: 5rem;
    color: ${styles.colors.primaryFont};
    ${styles.transObject};
  }
  & > a:hover {
    color: goldenrod;
  }
  & > svg {
    font-size: 5rem;
  }
`

const SponsorsWrapper = styled.div`
  width: 100%;
  & > img {
    width: 100%;
    max-width: 60rem;
    display: block;
    margin: 0 auto;
  }
`
