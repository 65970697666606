import React, { useContext } from "react"
import styled from "styled-components"
import { Section, Title } from "../utils"
import ClipOostende from "../images/oostende.png"
import ClipEsprit from "../images/esprit.png"
import LanguageContext from "../contexts/LanguageContext"

const Videos = () => {
  const { lang } = useContext(LanguageContext)
  return (
    <Section id="videos">
      <Title
        text={
          lang === "fr"
            ? "clips videos"
            : lang === "nl"
            ? "Videoclips"
            : "Video clips"
        }
      />
      <VideosWrapper>
        <VideoWrapper>
          <a
            href="https://youtu.be/bH8x447yeNI"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ClipOostende} alt="Oostende bonsoir" />
          </a>
        </VideoWrapper>
        <VideoWrapper>
          <a
            href="https://youtu.be/yzbHqLMXkZM"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ClipEsprit} alt="Court-circuit dans mon esprit" />
          </a>
        </VideoWrapper>
      </VideosWrapper>
    </Section>
  )
}

export default Videos

const VideosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`

const VideoWrapper = styled.div`
  width: 45%;
  padding: 1rem;
  @media screen and (max-width: 560px) {
    padding: 1rem;
    width: 80%;
    margin: 0 auto;
    &:first-child {
      margin-bottom: 4rem;
    }
  }
  & > a {
    width: 100%;
  }
  & > a > img {
    width: 100%;
  }
`
