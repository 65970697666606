import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { MainHeader } from "../utils"
import Album from "../components/Album"
import Shows from "../components/Shows"
import Videos from "../components/Videos"
import SocialMedia from "../components/SocialMedia"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <MainHeader />
    <Album />
    <Shows />
    <Videos />
    <SocialMedia />
  </Layout>
)

export default IndexPage
